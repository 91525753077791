<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Reporte de estudiantes"></header-title>
      <p class="grey--text text--darken-1">Estudiante de ESFM y UA.</p>
      <v-row>
        <v-col cols="12" sm="3">
          <v-select
            label="Periodo y Gestión"
            :items="periodYears"
            v-model="options.period_years"
            item-text="periodo_gestion"
            item-value="periodo_gestion"
            @change="getStudentStaffYear"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            label="Especialidad"
            :items="specialities"
            v-model="options.speciality_id"
            item-text="descripcion"
            item-value="id"
            @change="getStudentStaff"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="mr-1"
            label="Buscar"
            v-model="options.search"
            append-icon="mdi-magnify"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            fab
            small
            color="error"
            class="mr-1"
            @click="reportStudentStaff"
            :disabled="studentStaffs.length == 0"
          >
            PDF
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="studentStaffs"
        :search="options.search"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, 150, -1],
        }"
        calculate-widths
        item-key="name"
        class="elevation-2 mt-4"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{ studentStaffs.indexOf(item) + 1 }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="mr-1"
                  type="button"
                  color="info"
                  :loading="btn_loading"
                  @click="showDetail(item.codigo)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-view-list-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.tipo_cargo == 'DE' || item.tipo_cargo == 'DU'"
                  small
                  type="button"
                  color="success"
                  :loading="btn_loading"
                  @click="showSchedule(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-table-large</v-icon>
                </v-btn>
                <v-btn v-else small :disabled="true">
                  <v-icon>mdi-table-large</v-icon>
                </v-btn>
              </template>
              <span>Ver Horario</span>
            </v-tooltip> -->
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="detailDialog" scrollable max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Detalle</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h4 class="teal--text">Datos personales</h4>
          <personal-information :person="student" />
          <h4 class="teal--text">Modalidad de ingreso</h4>
          <v-container grid-list-md>
            <v-row dense>
              <v-col cols="12">
                <b>Modalidad: </b>
                <b v-if="student.modalidad" class="blue--text">
                  {{ student.modalidad }}
                </b>
                <span v-else>sin dato</span>
              </v-col>
              <v-col cols="12">
                <b>Tipo de estudiante: </b>
                {{ student.cargo ? student.cargo : "sin dato" }}
              </v-col>
            </v-row>
          </v-container>
          <h4 class="teal--text">Ubicación</h4>
          <person-location-data :person="student" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click.native="detailDialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-if="scheduleDialog"
      v-model="scheduleDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Horario del/la docente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="red" dark @click="scheduleDialog = false">
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="8" class="mt-2">
                <v-autocomplete
                  outlined
                  label="Periodo y Gestión"
                  :items="periodYears"
                  v-model="year_id"
                  item-text="periodo_gestion"
                  item-value="periodo_gestion"
                  @change="getTeacherSchedule"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="teal--text text--darken-2">Datos del/la docente</h4>
            <v-row class="mt-2">
              <v-col class="py-0" cols="12" sm="12">
                Código:
                <span class="grey--text text--darken-2">{{
                  person.codigo
                }}</span></v-col
              >
              <v-col class="py-0" cols="12" sm="12">
                Apellidos y Nombres:
                <span class="grey--text text--darken-2">{{
                  person.nombre
                }}</span></v-col
              >
              <v-col class="py-0" cols="12" sm="12"
                >ESFM/UA:
                <span class="grey--text text--darken-2">{{
                  person.esfm_ua
                }}</span></v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <div class="mx-4" v-if="unitTrainingAssigneds.length > 0">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Unidades de Formación asignadas
          </h4>
          <v-row>
            <v-col cols="12">
              <div class="v-data-table v-data-table--dense theme--light">
                <div class="v-data-table__wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Especialidad</th>
                        <th class="text-left">Año de formación</th>
                        <th class="text-left">Paralelo</th>
                        <th class="text-left">Estudiantes</th>
                        <th class="text-left">Código UF</th>
                        <th class="text-left">Unidad de formación</th>
                        <th class="text-left">Horas semana</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in unitTrainingAssigneds"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.especialidad }}</td>
                        <td>{{ item.semestre }}</td>
                        <td>{{ item.paralelo }}</td>
                        <td>{{ item.estudiantes }}</td>
                        <td>
                          {{ item.cod_modulo }} |
                          {{ item.sistema == "A" ? "Anual" : "Semestral" }}
                        </td>
                        <td>{{ item.uf }}</td>
                        <td>
                          {{ item.horas_semana }} (anual
                          {{ item.horas_gestion }})
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          class="text-center grey--text text--darken-2"
                          colspan="4"
                        >
                          <b>Total estudiantes</b>
                        </td>
                        <td class="grey--text text--darken-2">
                          <b>{{ totalStudent }}</b>
                        </td>
                        <td
                          class="text-center grey--text text--darken-2"
                          colspan="2"
                        >
                          <b>Total horas semana</b>
                        </td>
                        <td class="grey--text text--darken-2">
                          <b
                            >{{ totalHours }} (mes {{ totalHours * 4 }} hrs.)</b
                          >
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mx-4" v-if="workshopTrainingAssigneds.length > 0">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Taller de Formación complementaria
          </h4>
          <v-row>
            <v-col cols="12">
              <div class="v-data-table v-data-table--dense theme--light">
                <div class="v-data-table__wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Año de formación</th>
                        <th class="text-left">Código</th>
                        <th class="text-left">Taller</th>
                        <th class="text-left">Horas semana</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in workshopTrainingAssigneds"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item.des_semestre }} - {{ item.nom_modulo }} -
                          {{ item.paralelo }}
                        </td>
                        <td>{{ item.cod_taller }}</td>
                        <td>{{ item.nom_taller }}</td>
                        <td>{{ item.horas_semana }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="text-center" colspan="4">
                          <b>Total horas semana</b>
                        </td>
                        <td class="text-rights">
                          <b>{{ totalWorkshop }}</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mx-4" v-if="workshopTrainingAssigneds.length == 0">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Horario del/la docente
          </h4>
          <v-row>
            <v-col cols="12">
              <div class="v-data-table theme--light">
                <div class="v-data-table__wrapper">
                  <table>
                    <tbody class="schedule">
                      <tr
                        v-for="(item, index) in unitTrainings"
                        :key="index"
                      >
                        <td>{{ item[0] }}</td>
                        <td>{{ item[1] }}</td>
                        <td>{{ item[2] }}</td>
                        <td>{{ item[3] }}</td>
                        <td>{{ item[4] }}</td>
                        <td>{{ item[5] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog> -->
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import PersonalInformation from "@/components/PersonalInformation";
import PersonLocationData from "@/components/PersonLocationData.vue";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import PDFService from "../../utils/pdf";
import Service from "../../services/request";
import { mapActions } from "vuex";

export default {
  name: "student-staff",
  components: {
    HeaderTitle,
    PersonalInformation,
    PersonLocationData,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      pdf_loading: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Especialidad ", value: "especialidad", sortable: false },
        { text: "Código ", value: "codigo", sortable: false },
        { text: "Apellidos y Nombres ", value: "nombre" },
        { text: "Cédula Identidad ", value: "cedula" },
        { text: "Género ", value: "genero" },
        { text: "Teléfono", value: "telefono", sortable: false },
        { text: "Acciones ", value: "actions", sortable: false },
      ],
      periodYears: [],
      institutions: [],
      specialities: [],
      unitTrainingAssigneds: [],
      workshopTrainingAssigneds: [],
      unitTrainings: [],
      studentStaffs: [],
      student: {
        canton: "",
        cedula: "",
        codigo: "",
        correo: "",
        departamento: "",
        domicilio: "",
        estado: null,
        estado_civil: "",
        expedido: "",
        fecha_etado: "",
        fecha_nacimiento: "",
        genero: "",
        grupo_sangui: "",
        localidad: "",
        municipio: "",
        nombre: "",
        profesion: "",
        provincia: "",
        rda: "",
        telefono: "",
        modalidad: "",
      },
      person: {
        codigo: "",
        nombre: "",
        esfm_ua: "",
      },
      options: {
        search: "",
        period_years: "",
        institution_id: "",
        speciality_id: "",
        request_type: "a",
      },
      detailDialog: false,
      scheduleDialog: false,
      user: null,
      serve: "",
    };
  },
  computed: {
    totalStudent() {
      return this.unitTrainingAssigneds.reduce(
        (acc, item) => acc + item.estudiantes,
        0
      );
    },
    totalHours() {
      return this.unitTrainingAssigneds.reduce(
        (acc, item) => acc + item.horas_semana,
        0
      );
    },
    totalWorkshop() {
      return this.workshopTrainingAssigneds.reduce(
        (acc, item) => acc + item.horas_semana,
        0
      );
    },
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    const roles = [8, 11];
    this.user = Storage.getUser();
    if (this.user && roles.includes(this.user.rid)) {
      this.options.institution_id = this.user.ies;
      this.getPeriodYearParameter();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getPeriodYearParameter() {
      Service.svcPeriodYears("get")
        .then((response) => {
          this.periodYears = response.data[0];
          const year = this.periodYears.find((item) => item.vigente == 1);
          this.options.period_years = year ? year.periodo_gestion : "";
          this.getSpecialityParameter();
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    getStudentStaffYear() {
      this.options.institution_id = "";
      this.options.speciality_id = "";
      this.studentStaffs = [];
    },
    getSpecialityParameter() {
      this.options.speciality_id = "";
      this.specialities = [];
      const [, year] = this.options.period_years.split("/");
      Service.svcSpecialityParameter("post", {
        institution_id: this.options.institution_id,
        year,
      })
        .then((response) => {
          this.specialities = [
            { id: "", descripcion: "Todos" },
            ...response.data,
          ];
          this.getStudentStaff();
        })
        .catch((error) => {
          console.error("No cargo los registros", error);
        });
    },
    getStudentStaff() {
      if (this.options.period_years && this.options.institution_id) {
        this.loading = true;
        this.studentStaffs = [];
        Service.svcStudentStaff("post", this.options)
          .then((response) => {
            this.loading = false;
            this.studentStaffs = response.data;
          })
          .catch((error) => {
            this.loading = false;
            console.error("Error al cargar registros", error);
          });
      } else {
        this.toast({
          color: "info",
          text: "Seleccione periodo e institución",
        });
      }
    },
    showDetail(person_code) {
      this.btn_loading = true;
      Service.svcPersonById("get", { person_code: person_code })
        .then((response) => {
          this.btn_loading = false;
          this.student = response.data;
          this.detailDialog = true;
        })
        .catch((error) => {
          this.btn_loading = false;
          console.error("No cargo el registro", error);
        });
    },
    reportStudentStaff() {
      this.pdf_loading = true;
      const institution = this.institutions.find(
        (item) => item.codigo === this.options.institution_id
      );
      PDFService.pdfStudentStaff(
        "ESTUDIANTES",
        this.options.period_years,
        institution,
        this.studentStaffs
      );
      this.pdf_loading = false;
    },
  },
};
</script>
